import React, { FC, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../Shared/Header/Header';
import { Badge, BottomNavigation, BottomNavigationAction, Box, Container, useTheme } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GeneralMenu from '../GeneralMenu/GeneralMenu';
import Recharge from '../Recharge/Recharge';

interface WrapperProps {}

const Wrapper: FC<WrapperProps> = () => {
   const [dialogRechargeOpen, setDialogRechargeOpen] = useState(false);
   const [generalMenuOpen, setGeneralMenuOpen] = useState(false);
   const [navigationValue, setNavigationValue] = useState('');
   const navigate = useNavigate();
   const theme = useTheme();

   const handleRecharge = () => {
      setDialogRechargeOpen(!dialogRechargeOpen);
   }

   const handleGeneralMenu = () => {
      setGeneralMenuOpen(!generalMenuOpen);
   }

   const rechargeOnClose = () => {
      setDialogRechargeOpen(!dialogRechargeOpen);
   }

   const handleChangeNavigationFromGeneralMenu = (destination: string) => {
      routingMenagement(destination);
   }

   const handleChangeNavigation = (event: React.SyntheticEvent, newValue: string) => {
      routingMenagement(newValue);
   };

   const routingMenagement = (destination: string)=> {
      if(generalMenuOpen){
         handleGeneralMenu();
      }
      setNavigationValue(destination);
      switch (destination) {
         case 'home':
            navigate('/home', { replace: true });
            break;

         case 'location':
            navigate('/location', { replace: true });
            break;
         case 'user':
            navigate('/user', { replace: true });
            break;
         case 'transactions':
            navigate('/transactions', { replace: true });
            break;
         case 'scanqr_barcode':
            navigate('/codes', { replace: true });
            break;   

         default:
            break;
      }
   }


   return (
      <Container maxWidth={false} sx={{ width: '100vw', height: '100vh'  }} disableGutters={true}>
         <Header visible={true} generalMenuStatus={generalMenuOpen}  openRecharge={handleRecharge} openGeneralMenu={handleGeneralMenu}/>
         <Box className="middle-box">
            {generalMenuOpen ?<GeneralMenu goTo={handleChangeNavigationFromGeneralMenu}></GeneralMenu> : <Outlet />}
         </Box>
         <BottomNavigation
            sx={{ 
               width: '100vw',
               alignItems:'center',
               justifyContent: 'center',
               bottom: 0,
               position: 'fixed',
               backgroundColor: `${theme.palette.background.default}` 
            }}
            value={navigationValue}
            onChange={handleChangeNavigation} 
         >
            <BottomNavigationAction 
               value="home"
               icon={<HomeIcon sx={{ color: 'black' }} />} 
            />
            <BottomNavigationAction 
               value="notification"
               icon={<Badge badgeContent={4}><MailIcon sx={{ color: 'black' }} /></Badge>} 
            />
            <BottomNavigationAction 
               value="scanqr_barcode"
               icon={<QrCode2Icon sx={{ color: 'black' }} />} 
            />
            <BottomNavigationAction 
               value="scanqr"
               icon={<QrCode2Icon sx={{ color: 'black' }} />} 
            />
            <BottomNavigationAction 
               value="location"
               icon={<LocationOnIcon sx={{ color: 'black' }} />} 
            />
         </BottomNavigation>
         <Recharge open={dialogRechargeOpen} onClose={rechargeOnClose}></Recharge>
      </Container>
   )
};

export default Wrapper;
