/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppBar, Avatar, Box, Button, Chip, Container, Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './Header.scss'
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Currency, getCurrency, getUserInformation, setUserCurrency } from '../../../Features/User/userSlice';
import { SuccessGetUserResponse } from '../../../Services/Functions/User';


interface HeaderProps {
   visible: boolean;
   generalMenuStatus: boolean;
   openGeneralMenu: () => void;
   openRecharge: () => void; 
}

const Header: FC<HeaderProps> = (props) => {
   const {visible, generalMenuStatus, openGeneralMenu, openRecharge } = props;
   const currentUser: SuccessGetUserResponse | null = useSelector(getUserInformation);

   const convertAmount = (amount: number = 0) => {
      const convertedAmount =  amount / 100;
      return convertedAmount.toFixed(2);
   }

   const [currency, setCurrency] = useState<Currency | null>(useSelector(getCurrency));
   const [amount, setAmount] = useState(convertAmount(currentUser?.wallet?.amount ?? 0));
   const { t } = useTranslation();
   const theme = useTheme();
   const dispatch = useDispatch();

   useEffect(() => {
      if(!currency){
         const currency: Currency = Object.assign({});
         currency.code = currentUser?.fi_sitegroup?.currency ?? '';
         currency.symbol = currencySymbol(currency.code);
         setCurrency({...currency});
         dispatch(setUserCurrency({...currency}));
      }
      setAmount(convertAmount(currentUser?.wallet?.amount ?? 0));
   }, [currency, currentUser, dispatch]);

   const currencySymbol = (currency: string = '') => {
      let currencySymbol = ''
      switch (currency.toUpperCase()) {
         case 'EUR':
            currencySymbol = '€';
            break;
         case 'USD':
            currencySymbol = '$';
            break;   
      
         default:
            currencySymbol = '€';
            break;
      }

      return currencySymbol;
   }

   return (
      <Container
         maxWidth={false}
         className="border-bottom-distortion"            
         sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '110px',
            width: '100vw',
            p:1,
            backgroundColor: `${theme.palette.background.default}`,
            boxShadow: 3,
            position: 'fixed',
            zIndex: 1000
         }}
      >

         <AppBar position="static" sx={{ backgroundColor: `${theme.palette.background.default}`, boxShadow: 'none' }}>
            <IconButton
               size="large"
               edge="start"
               color="inherit"
               aria-label="menu"
               sx={{
                  p: 0,
                  ml:1,
                  justifyContent: 'flex-start',
                  color: 'black'
               }}
               onClick={props.openGeneralMenu}
            >
               { generalMenuStatus ? <CloseIcon />: <MenuIcon />}
            </IconButton>
         </AppBar>      

         <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
         >
            <Grid item xs={2}>
               <Avatar>IMG</Avatar>
            </Grid>
            <Grid item xs={4}>
               <Typography>{t('user')}</Typography>
               <Typography fontWeight="bold">{ currentUser?.first_name }</Typography>
            </Grid>
            <Grid item xs={6}>
               <Box 
                  sx={{
                     height: '40px',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     border: '1px solid',
                     borderRadius:'10px',
                     p:1,
                     bgcolor: '#fff',
                     borderColor: `${theme.palette.secondary.main}`,
                     boxShadow: 5
                  }}>
                  <Box sx={{display: 'flex',flexDirection: 'row', alignItems:'center', gap: 1, mb: .5}}>
                     <Typography fontWeight="bold" fontSize="14px"> {t('my_wallet')} </Typography>
                     <Chip avatar={<Avatar>{currency?.symbol}</Avatar>} label={amount} variant="outlined" />
                  </Box>
                  <Button color="primary" variant="contained" sx={{display: 'flex', width: '100%', height: '20px', zIndex: 1, fontSize: '11px'}} onClick={props.openRecharge}>{t('buttons.recharge')}</Button>
               </Box>   
            </Grid>
         </Grid>
      </Container>
   )
};

export default Header;
