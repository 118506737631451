import { Box } from '@mui/material';
import React, { FC, useEffect, useState, useTransition } from 'react';
import './Codes.scss';
import { getAccessToken, getMemberId } from '../../Features/Login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../MessageHandler/MessageService';
import QRCode from "react-qr-code";


interface CodesProps {}

const Codes: FC<CodesProps> = () => {

   const [qrValue, setQrValue] = useState('pippo');

   const memberId = useSelector(getMemberId);
   const accessToken = useSelector(getAccessToken);
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const message = useMessage();

   useEffect(()=> {

   },[]);

   return (
      <Box className="qr-container">
         <Box sx={{background: 'white', p: 5, maxWidth: 200, border: '2px solid' }}>
            <QRCode
               size={256}
               style={{ height: "auto", maxWidth: "100%", width: "100%" }}
               value={qrValue}
               viewBox={`0 0 256 256`}
               />
         </Box>
      </Box>
   )
};

export default Codes;
