import { Button, Card, CardActions, CardContent, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FC } from "react";

 interface RechargeProps {
	open: boolean;
	onClose: () => void;
  }
  
const Recharge: FC<RechargeProps> = (props) => {
	const {open, onClose} = props;

	const handleClose = () => {
		onClose();
	};

	const handleListItemClick = (value: string) => {
		onClose();
	};

	return (
		<Dialog open={open} fullScreen>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					onClick={handleClose}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
				<Typography sx={{ ml: 3 }} variant="h6" component="div">
					Recharge
				</Typography>
          	</Toolbar>
			<Card sx={{ minWidth: 275, m: 2 }}>
				<CardContent>
					<Typography  color="text.secondary" gutterBottom>
						Messaggio di prova
					</Typography>
				</CardContent>
				<CardActions>
					<Button size="small">Learn More</Button>
				</CardActions>
			</Card>
		</Dialog>
	);
}

export default Recharge;