
import React, { FC, useState } from 'react';
import './GeneralMenu.scss';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken, getMemberId, setAccessToken } from '../../Features/Login/loginSlice';
import { logout } from '../../Services/Functions/Logout';
import { useMessage } from '../MessageHandler/MessageService';
import { useNavigate } from 'react-router-dom';

interface GeneralMenuProps {
  goTo: (destination: string) => void;
}


const GeneralMenu: FC<GeneralMenuProps> = (props) => {
  const [openUserProfile, setOpenUserProfile] = useState(false);

  const message = useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberId = useSelector(getMemberId);
  const accessToken = useSelector(getAccessToken);

  const handleUserProfileClick = () => {
    setOpenUserProfile(!openUserProfile);
  };

  const handleModifyUser = () => {
    props.goTo('user');
  }

  const handleTransactions = () => {
    props.goTo('transactions');
  }

  const handleLogout = async () => {
    const logoutResponse = await logout(memberId, accessToken);
    if('error_code' in logoutResponse){
      let decodedMessage = '';
      switch (logoutResponse.error_code) {
        case '':
          decodedMessage = '';
          break;
        default:
          decodedMessage = t("errors.general");
          break;
      }
      message({
        title: t("messages.attention").toUpperCase(),
        description: decodedMessage,
        okCallback: () => {},
        okLabel: t("buttons.ok")
      })
    }else{
      dispatch(setAccessToken(''));
      navigate('/login', { replace: true });
    }
  };

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
    >
      <ListItemButton onClick={handleUserProfileClick}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={t("general_menu.my_profile")} />
        {openUserProfile ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openUserProfile} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={handleModifyUser}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t("general_menu.modify_user")} />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={handleTransactions}>
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText primary={t("general_menu.transactions")} />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={t("general_menu.logout")} />
      </ListItemButton>
    </List>
  )
};

export default GeneralMenu;
