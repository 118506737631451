import React, { useEffect, useState } from "react";
import { Box, Divider,  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { TransactionItem } from "../../../Services/Functions/Transactions";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';



const TransactionDetail: React.FC<{}> = () => {

   const { t } = useTranslation();
   const { state } = useLocation();
   const navigate = useNavigate();
   const [detail, setDetail] = useState<TransactionItem[]>(state);
   
   console.log(detail);


   return (
      <Box sx={{p:2}}>
         <Box>
            <ArrowBackOutlinedIcon onClick={() => navigate(-1)}></ArrowBackOutlinedIcon>
         </Box>
         <Divider></Divider>
         <Box sx={{ mt:1 }}>
            detail
         </Box>
      </Box>
   );
};

export default TransactionDetail;
