import { LayoutConfig } from "./ThemeConfig";

const defaultSystemTheme: LayoutConfig = {
	themeName: "System Labware",
	color: {
		primary: "#8c1a1a",
		secondary: "#FFFFFF",
		background: "#ebebeb"
	}
};

const preset1: LayoutConfig = {
	themeName: "Preset Labware 1",
	color: {
		primary: "#8c1a1a",
		secondary: "#FFFFFF",
		background: "#ebebeb"
	}
};

const ConfigPresets = {
	system: defaultSystemTheme,
	custom: preset1
};

export default ConfigPresets;
