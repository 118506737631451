import { useState, useEffect } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';

type Method = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";

export interface axiosRequestInterface {
	method: Method,
	url: string,
	body: string,
	headers: string,
	cashsystem?: boolean
	retry?: number
}

export interface callAxiosResponseInterface {
	status: any,
	data: any
}

const wait = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const callAxios = (props: axiosRequestInterface, retryNumber: number = 0, delay = 500) : Promise<callAxiosResponseInterface> =>  {
	return new Promise(async (resolve, reject) => {

		let output: callAxiosResponseInterface = {
			status: null,
			data: null
		}

		let configs = props.cashsystem ? { headers: props.headers } : {
			headers: {
				'Content-Type': 'application/json',
				kioskauth: (localStorage.getItem('Username') ?? '') + ';' + (localStorage.getItem('Password') ?? ''),
				...JSON.parse(props.headers)
			}
		};

		const axiosInstance: Promise<AxiosResponse<any, any>> = (props.method === 'get')
			? axios[props.method](props.url, configs)
			: axios[props.method](props.url, JSON.parse(props.body), configs);
		axiosInstance
			.then((resp: AxiosResponse) => {
				output.status = resp.status;
				output.data = resp.data
				resolve(output);
			})
			.catch((err: AxiosError) => {
				if (err.response) {
					if(retryNumber > 0 && err.response?.status === 400){
						return wait(delay).then(() =>{
							callAxios(props,retryNumber--,delay);
						})
					}
					// The request was made and the server responded with a status code that falls out of the range of 2xx
					output.status = err.response?.status;
					output.data = err.response?.data
				} else {
					// if (err.request) The request was made but no response was received
					// otherwise Something happened in setting up the request that triggered an Error
					output.status = "503";
					output.data = { error_code: err.code }
				}
				resolve(output);
			})
	});
}

export const useAxios = <T extends unknown>({ method, url, body, headers }: axiosRequestInterface): { response: T, isError: boolean, isLoading: boolean } => {
	const [response, setResponse] = useState(null as T);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {

		const fetchData = () => {

			callAxios({ method, url, body, headers })
				.then((callAxiosResponse) => {
					setResponse(callAxiosResponse.data);
					setIsError(callAxiosResponse.status >= 300);
				})
				.catch((err) => {
					setResponse(null as T);
					setIsError(true);
				})
				.finally(() => {
					setIsLoading(false);
				});
		};

		fetchData();
	}, [method, url, body, headers]);

	return {
		response,
		isError,
		isLoading
	};
};