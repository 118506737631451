import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./Main.scss";
import Home from "./Home/Home";
import React, { Suspense } from "react";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { Backdrop, CircularProgress } from "@mui/material";
import Wrapper from "./Wrapper/Wrapper";
import Location from "./Location/Location";
import User from "./UserProfile/User";
import Transactions from "./Transactions/Transactions";
import TransactionDetail from "./Transactions/Detail/TransactionDetail";
import Codes from "./Codes/Codes";

/** Main wrapper for the whole app. */
function Main(): JSX.Element {

	const Login = React.lazy(() => import('./Login/Login'));
	const Register = React.lazy(() => import('./Register/Register'));
	const Privacy = React.lazy(() => import('./Privacy/Privacy'));
	const TermsCondition = React.lazy(() => import('./TermsCondition/TermsCondition'));
	const ForgotPassword = React.lazy(() => import('./ForgotPassword/ForgotPassword'));

	return (
		<div className="Main">
			<Router>
				<Suspense fallback={<Backdrop sx={{ color: '#fff', zIndex: 99 }} open={true} > <CircularProgress color="inherit" /></Backdrop>}>
					<Routes>
						<Route path='/' element={<Wrapper />}>
							<Route index element={<Navigate to='home' />}/>
							<Route path='home' element={<PrivateRoute><Home /></PrivateRoute>}/>
							<Route path='location' element={<PrivateRoute><Location /></PrivateRoute>}/>
							<Route path='user' element={<PrivateRoute><User /></PrivateRoute>}/>
							<Route path='transactions' element={<PrivateRoute><Transactions /></PrivateRoute>}/>
							<Route path="transactions/:id" element={<PrivateRoute><TransactionDetail /></PrivateRoute>} />
							<Route path="codes" element={<PrivateRoute><Codes /></PrivateRoute>} />
						</Route>
						<Route path='login' element={<Login />} />
						<Route path='register' element={<Register />} />
						<Route path='privacy' element={<Privacy />} />
						<Route path='terms_condition' element={<TermsCondition />} />
						<Route path='forgot_password' element={<ForgotPassword />} />
						
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
				</Suspense>
			</Router>
		</div>
	);
}

export default Main;
