import { useSelector } from "react-redux";
import { callAxios } from "../../Core/AxiosWrapper";

export interface SuccessLogoutResponse {
	message: string;
}

export interface ErrorLogoutResponse {
	error_code: string;
}

export const logout = async (member_id: number | null, access_token: string): Promise<SuccessLogoutResponse | ErrorLogoutResponse> => {
	const HOST = process.env.REACT_APP_4LOYALTY_BASE_URL ?? "";

	const url = HOST + "/app/v1/" + member_id + "/logout";

	const response = await callAxios({
		method: "get",
		url: url,
		body: "",
		headers: JSON.stringify({ Authorization: `Bearer ${access_token}` })
	});

	return response.data;
};
