import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, IconButton, Paper, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import './Home.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { grey } from '@mui/material/colors';
import paella from '../../paella.jpg';
import { getAccessToken, getMemberId, setAccessToken } from '../../Features/Login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { get_user } from '../../Services/Functions/User';
import { setUser } from '../../Features/User/userSlice';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../MessageHandler/MessageService';


interface HomeProps {}

const Home: FC<HomeProps> = () => {

   const memberId = useSelector(getMemberId);
   const accessToken = useSelector(getAccessToken);
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const message = useMessage();

   useEffect(()=> {
      const getUser = async ()=> {
         const userResponse = await get_user(memberId, accessToken);
         if('error_code' in userResponse){
            let decodedMessage = '';
            switch (userResponse.error_code) {
               case 'user_unauthorized':
                  dispatch(setAccessToken(''));
                  dispatch(setUser(null));
                  break;
               case 'sitegroup_not_found':
                  decodedMessage = t("errors.sitegroup_not_found");
                  break;
               default:
                  decodedMessage = t("errors.general");
                  break;
            }
            message({
               title: t("messages.attention").toUpperCase(),
               description: decodedMessage,
               okCallback: () => {},
               okLabel: t("buttons.ok")
             })
         }else{
            dispatch(setUser(userResponse));
         }
      } 
      getUser();
   },[]);

   return (
      <Box>
         <Paper elevation={0} 
            sx={{
               display: 'flex',
               overflowX: 'scroll',
               overscrollBehaviorInline: 'contain',
               scrollSnapType: 'x mandatory',
               minHeight: '150px',
               backgroundColor: grey[50],
               gap: 2,
               m: 2,
               p: 2
            }}>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                     together with your guests. Add 1 cup of frozen peas along with the mussels,
                     if you like.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300,  scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect
                  </Typography>
               </CardContent>
            </Card>
         </Paper>
         <Paper elevation={0} 
            sx={{
               display: 'flex',
               overflowX: 'scroll',
               overscrollBehaviorInline: 'contain',
               scrollSnapType: 'x mandatory',
               minHeight: '150px',
               backgroundColor: grey[50],
               gap: 2,
               m: 2,
               p: 2
            }}>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                     together with your guests. Add 1 cup of frozen peas along with the mussels,
                     if you like.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect
                  </Typography>
               </CardContent>
            </Card>
         </Paper>
         <Paper elevation={0} 
            sx={{
               display: 'flex',
               overflowX: 'scroll',
               overscrollBehaviorInline: 'contain',
               scrollSnapType: 'x mandatory',
               minHeight: '150px',
               backgroundColor: grey[50],
               gap: 2,
               m: 2,
               p: 2
            }}>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                     together with your guests. Add 1 cup of frozen peas along with the mussels,
                     if you like.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect party dish and a fun meal to cook
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ minWidth: 300, scrollSnapAlign: 'center' }}>
               <CardHeader
                  avatar={
                     <Avatar aria-label="recipe">
                        R
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings">
                        <MoreVertIcon />
                     </IconButton>
                  }
                  title="Shrimp and Chorizo Paella"
                  subheader="September 14, 2016"
               />
               <CardMedia
                  component="img"
                  height="120"
                  image={paella}
                  alt="Paella dish"
               />
               <CardContent>
                  <Typography variant="body2" color="text.secondary">
                     This impressive paella is a perfect
                  </Typography>
               </CardContent>
            </Card>
         </Paper>
      </Box>
   )
};

export default Home;
