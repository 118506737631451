import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getAccessToken } from "../../Features/Login/loginSlice";
import { useSelector } from "react-redux";

const PrivateRoute = ({children } : any) => {
	const accessToken = useSelector(getAccessToken);
	const [currentAccessToken,  setCurrentAccessToken] = useState(accessToken);

	useEffect(() => {
		setCurrentAccessToken(accessToken);
	}, [accessToken])

	return currentAccessToken ? children : <Navigate to="/login" />
}

export default PrivateRoute;