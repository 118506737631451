"use client";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./Components/Main";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n";
import { Provider } from 'react-redux'
import { persistor, store } from './app/store'
import {ThemeProvider } from "@mui/material";

import { AppConfig, AppTheme, buildTheme, defaultAppConfig } from "./Components/Theme/ThemeConfig";
import { MessageServiceProvider } from "./Components/MessageHandler/MessageService";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const currentConfig: AppConfig = defaultAppConfig;

// Build the MUI Theme using the chosen Configurations
const defaultTheme: AppTheme = buildTheme(currentConfig);
export const customTheme = defaultTheme.customTheme;
export const systemTheme = defaultTheme.systemTheme;

localStorage.removeItem("site_group");
const params = new URLSearchParams(window.location.search);
const SITE_GROUP = params.get("site_group") ?? "";
localStorage.setItem("site_group", SITE_GROUP);

// The theme provider is declare here and handled in Main
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ThemeProvider theme={customTheme}>
					<MessageServiceProvider>
						<Main />
					</MessageServiceProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
