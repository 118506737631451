import { callAxios } from "../../Core/AxiosWrapper";

export interface TransactionItem {
	category: string;
	name: string;
	gross_value: number;
	sku: string;
	quantity: number;
}

export interface TransactionData {
	channel_name: string;
	currency: string;
	datetime: string;
	debt_value: number;
	document_number: string;
	gross_value: number;
	labels: string;
	refunded: boolean;
	site_code: string;
	transaction_id: number;
	type: string;
	items: TransactionItem[];
}

export interface TransactionLink {
	url: string;
	active: boolean;
	label: string;
}

export interface SuccessTransactionsResponse {
	current_page: number;
	data: TransactionData[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	next_page_url: string;
	path: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
	links: TransactionLink[];
}

export interface ErrorTransactionsResponse {
	error_code: string;
}

export const get_transactions = async (
	member_id: number | null,
	access_token: string,
	perPage: number,
	currentPage: number
): Promise<SuccessTransactionsResponse | ErrorTransactionsResponse> => {
	const HOST = process.env.REACT_APP_4LOYALTY_BASE_URL ?? "";
	const url = HOST + "/app/v1/" + member_id + "/transactions?page=" + currentPage + "&per_page=" + perPage;

	const response = await callAxios({
		method: "get",
		url: url,
		body: "",
		headers: JSON.stringify({ Authorization: `Bearer ${access_token}` })
	});

	return response.data;
};
