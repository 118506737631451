import { Theme, ThemeOptions, createTheme } from "@mui/material";
import ConfigPresets from "./ThemePresets";
import { red } from '@mui/material/colors';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
export type Color = RGB | RGBA | HEX;

export interface ColorSet {
	primary: Color;
	secondary: Color;
	background: Color;
}

export interface LayoutConfig {
	themeName: string;
	color: ColorSet;
}

export interface AppTheme {
	config: AppConfig;
	customTheme: Theme;
	systemTheme: Theme;
}

export interface AppConfig {
	themes: ThemeConfig;
}

export const defaultAppConfig: AppConfig = {
	themes: {
		system: ConfigPresets.system,
		custom: ConfigPresets.custom
	}
};

export interface ThemeConfig {
	system: LayoutConfig;
	custom: LayoutConfig;
}


function createFromConfig(subConfig: LayoutConfig): Theme {
	const theme = createTheme({
		palette: {
			primary: {
				main: subConfig.color.primary
			},
			secondary: {
				main: subConfig.color.secondary
			},
			background: {
				default: subConfig.color.background,
			},
			error: {
				main: red[500]
			}
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						backgroundColor: `${subConfig.color.primary}`,
						color: `${subConfig.color.secondary}`
					}
				}
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						'& label.Mui-focused': {
							color: `${subConfig.color.primary}`,
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: `${subConfig.color.primary}`,
							}
						}	
					}
				}
			},
			MuiChip: {
				styleOverrides: {
					root: {
						borderColor: `${subConfig.color.primary}`,
						'& .MuiChip-avatar': {
							color: `${subConfig.color.secondary}`
						}
					},

				}
			},
			MuiAvatar: {
				styleOverrides: {
					root: {
						backgroundColor: `${subConfig.color.primary}`,
						color: `${subConfig.color.secondary}`
					}
				}
			}
		}
	});

	return theme;
}

export function buildTheme(config: AppConfig): AppTheme {
	return {
		config: config,
		customTheme: createFromConfig(config.themes.custom),
		systemTheme: createFromConfig(config.themes.system)
	};
}
