import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box  } from "@mui/material";

const Location: React.FC<{}> = () => {

  const navigate = useNavigate();

	return (
    <Box>
      Location
    </Box>
	);
};

export default Location;
