import { combineReducers, configureStore } from "@reduxjs/toolkit";
import headerReducer from "../Features/Header/headerSlice";
import loginReducer from "../Features/Login/loginSlice";
import userReducer from "../Features/User/userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["header", "login", "userData"]
};

const rootReducer = combineReducers({
	header: headerReducer,
	login: loginReducer,
	userData: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
