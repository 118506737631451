import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMessage } from "../MessageHandler/MessageService";
import { SuccessGetUserResponse } from "../../Services/Functions/User";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, getMemberId, setAccessToken } from "../../Features/Login/loginSlice";
import { getCurrency, getLanguage, getUserInformation } from "../../Features/User/userSlice";
import { TransactionData, get_transactions } from "../../Services/Functions/Transactions";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const Transactions: React.FC<{}> = () => {
   const currentUser: SuccessGetUserResponse | null = useSelector(getUserInformation);
   const memberId = useSelector(getMemberId);
   const accessToken = useSelector(getAccessToken);
   const lang = useSelector(getLanguage);

   const [loading, setLoading] = useState<boolean>(false);
   const [currency, setCurrency] = useState(useSelector(getCurrency));
   const [transactions, setTransactions] = useState<TransactionData[]>([]);
   const [totalTransactions, setTotalTransactions] = useState(0);
   const [currentPage, setCurrentPage] = useState(0);
   const [perPage, setPerPage] = useState(10);

   const { t } = useTranslation();
   const navigate = useNavigate();
   const message = useMessage();
   const dispatch = useDispatch();

   const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setCurrentPage(newPage);
      const per_page = perPage;
      getTransactions(per_page, newPage + 1);
   };
  
   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
   ) => {
      const per_page = parseInt(event.target.value, 10);
      const current_page = 0;
      setPerPage(per_page);
      setCurrentPage(current_page);
      getTransactions(per_page, current_page + 1);
   };

   useEffect(() => {
      const per_page = perPage;
      const current_page = currentPage + 1;
      getTransactions(per_page, current_page);
   }, []);

   const getTransactions = async (perPage: number, currentPage: number)=> {
      const transactionsResponse = await get_transactions(memberId, accessToken, perPage, currentPage);
      if('error_code' in transactionsResponse){
         switch (transactionsResponse.error_code) {
            case 'user_unauthorized':
               dispatch(setAccessToken(''));
               break;
         
            default:
               break;
         }
      }else{
         console.log(transactionsResponse);
         setTransactions(transactionsResponse.data);
         setTotalTransactions(transactionsResponse.total);
         setPerPage(transactionsResponse.per_page)
         const pageSelected = transactionsResponse.current_page > 0 ?  transactionsResponse.current_page - 1 : 0;
         setCurrentPage(pageSelected);
      }
   }

   const openDetailTransaction = (row: TransactionData) => {
      navigate(`/transactions/${row.transaction_id}`, { state: row.items });
   }

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '10px'
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

   return (
   <Box sx={{p:2}}>
      <TableContainer component={Paper}>
         <Table sx={{ minWidth: 300 }} aria-label="customized table">
         <TableHead>
            <TableRow>
               <StyledTableCell align="right">{t('transaction_table.channel_name')}</StyledTableCell>
               <StyledTableCell align="right">{t('transaction_table.gross_value')}</StyledTableCell>
               <StyledTableCell align="right">{t('transaction_table.date')}</StyledTableCell>
               <StyledTableCell align="right">{t('transaction_table.refunded')}</StyledTableCell>
               <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
         </TableHead>
         <TableBody>
            {transactions.map((row: TransactionData) => (
               <StyledTableRow key={row.transaction_id}>
                  <StyledTableCell align="right">{row.channel_name}</StyledTableCell>
                  <StyledTableCell align="right">{`${(row.gross_value / 100).toFixed(2)} ${currency?.symbol}`}</StyledTableCell>
                  <StyledTableCell align="right">{new Intl.DateTimeFormat(lang).format(new Date(row.datetime))}</StyledTableCell>
                  <StyledTableCell align="center" sx={{ p: 0 }}>
                     { row.refunded ?  <CheckCircleOutlineIcon></CheckCircleOutlineIcon> : null }
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ p: 0 }}>
                     <Button variant="outlined" onClick={() => openDetailTransaction(row)}>
                        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                     </Button>
                  </StyledTableCell> 
               </StyledTableRow>
            ))}
         </TableBody>
         </Table>
      </TableContainer>
      {totalTransactions > 0 ? <TablePagination
         component="div"
         count={totalTransactions}
         page={currentPage}
         onPageChange={handleChangePage}
         rowsPerPage={perPage}
         onRowsPerPageChange={handleChangeRowsPerPage}
         labelRowsPerPage={t('transaction_table.row_per_page')}
      /> : null}
   </Box>
   );
};

export default Transactions;
