import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

// Define a type for the slice state
interface LoginState {
	member_id: number | null;
	access_token: string;
}

// Define the initial state using that type
const initialState: LoginState = {
	member_id: null,
	access_token: ""
};

export const loginSlice = createSlice({
	name: "login",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setMemberId: (state, action: PayloadAction<number>) => {
			state.member_id = action.payload;
		},
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.access_token = action.payload;
		}
	}
});

export const { setMemberId, setAccessToken } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getMemberId = (state: RootState) => state.login.member_id;
export const getAccessToken = (state: RootState) => state.login.access_token;

export default loginSlice.reducer;
