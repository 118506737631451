import { callAxios } from "../../Core/AxiosWrapper";

export interface Address {
	street: string;
	address1: string;
	postal: string;
	city: string;
	province: string;
	country: string | null;
}

export interface Company {
	name: string;
	nip: string | null;
}

export interface Agreement {
	basic_legal: boolean;
	marketing: boolean;
	data_processing: boolean;
}

export interface UserTransactions {
	transactionsCount: number;
	transactionsAmount: number;
	transactionsAmountWithoutDeliveryCosts: number;
	averageTransactionAmount: number;
	lastTransactionDate: string;
	firstTransactionDate: string;
}

export interface Labels {
	key: string;
	value: string;
}

export interface Points {
	campaign_id: number;
	campaign_name: string;
	active_points: number;
	expired_points_at: string;
}

export interface Wallet {
	debt_status: boolean;
	debt_limit: number;
	amount: number;
}

export interface CardNumber {
	member_id: number;
	sitegroup_id: number;
	card_number: string;
}

export interface Fisitegroup {
	id: number;
	currency: string;
	digital_wallet: boolean;
	debt: boolean;
	web_app_enabled: boolean;
	campaigns_enabled: boolean;
}

export interface SuccessUserUpdateResponse {
	member_id: number;
}

export interface ErrorUserUpdateResponse {
	error_code: string;
}

export interface SuccessGetUserResponse {
	member_id: number;
	active: number;
	digital_wallet_type: number;
	first_name: string;
	last_name: string;
	gender: string | null;
	email: string;
	phone: string;
	birth_date: string | null;
	address: Address;
	company: Company;
	loyalty_card_numbers: CardNumber[];
	created_at: string;
	agreement: Agreement;
	updated_at: string;
	transactions: UserTransactions;
	labels: Labels;
	referral_token: string | null;
	points: Points[];
	wallet: Wallet;
	fi_sitegroup: Fisitegroup;
}

export interface ErrorGetUserResponse {
	error_code: string;
}

export interface userUpdateParams {
	name: string;
	surname: string;
	email: string;
	address: string;
	zip_code: string;
	city: string;
}

export const user_update = async (
	data: userUpdateParams,
	phone: string,
	member_id: number | null,
	access_token: string
): Promise<SuccessUserUpdateResponse | ErrorUserUpdateResponse> => {
	const HOST = process.env.REACT_APP_4LOYALTY_BASE_URL ?? "";
	const url = HOST + "/app/v1/" + member_id + "/update";

	const email = data.email.trim().toLowerCase();
	const bodyRequest = {
		first_name: data.name,
		last_name: data.surname,
		email: email,
		gender: "not_disclosed",
		phone: phone,
		address: {
			address1: data.address,
			postal: data.zip_code,
			city: data.city
		}
	};

	const response = await callAxios({
		method: "put",
		url: url,
		body: JSON.stringify(bodyRequest),
		headers: JSON.stringify({ Authorization: `Bearer ${access_token}` })
	});

	return response.data;
};

export const get_user = async (member_id: number | null, access_token: string): Promise<SuccessGetUserResponse | ErrorGetUserResponse> => {
	const HOST = process.env.REACT_APP_4LOYALTY_BASE_URL ?? "";
	const url = HOST + "/app/v1/" + member_id;

	const response = await callAxios({
		method: "get",
		url: url,
		body: "",
		headers: JSON.stringify({ Authorization: `Bearer ${access_token}` })
	});

	return response.data;
};
