import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import { SuccessGetUserResponse } from "../../Services/Functions/User";

export interface Currency {
	code: string;
	symbol: string;
}

// Define a type for the slice state
export interface UserState {
	user: SuccessGetUserResponse | null;
	language: string;
	currency: Currency | null;
}

// Define the initial state using that type
const initialState: UserState = {
	user: null,
	language: window.navigator.language,
	currency: null
};

export const userSlice = createSlice({
	name: "user",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<SuccessGetUserResponse | null>) => {
			state.user = action.payload;
		},
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		},
		setUserCurrency: (state, action: PayloadAction<Currency>) => {
			state.currency = action.payload;
		}
	}
});

export const { setUser, setLanguage, setUserCurrency } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUserInformation = (state: RootState) => state.userData.user;
export const getLanguage = (state: RootState) => state.userData.language;
export const getCurrency = (state: RootState) => state.userData.currency;

export default userSlice.reducer;
