import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useMessage } from "../MessageHandler/MessageService";
import { SuccessGetUserResponse, user_update } from "../../Services/Functions/User";
import { useSelector } from "react-redux";
import { getAccessToken, getMemberId } from "../../Features/Login/loginSlice";
import { getUserInformation } from "../../Features/User/userSlice";


const User: React.FC<{}> = () => {
  const currentUser: SuccessGetUserResponse | null = useSelector(getUserInformation);
  const memberId = useSelector(getMemberId);
  const accessToken = useSelector(getAccessToken);

	const [loading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState(currentUser?.phone ?? '');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const message = useMessage();

  type FormSchemaType = z.infer<typeof formSchema>;

  const formSchema = z.object({
    name: z.string().min(1, `${t("messages.required")}`).default(currentUser?.first_name ?? ''),
    surname: z.string().min(1, `${t("messages.required")}`).default(currentUser?.last_name ?? ''),
    email: z.string().min(1, `${t("messages.required")}`).email(`${t("messages.invalid", {name: t("email")})}`).default(currentUser?.email ?? ''),
    address: z.string(),
    zip_code: z.string(),
    city: z.string(),
  });
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormSchemaType> =  async (data) => {
    setLoading(true);
    const userUpdateResponse = await user_update(data, phone, memberId, accessToken);
    if('error_code' in userUpdateResponse){
      let decodedMessage = '';
      switch (userUpdateResponse.error_code) {
        case 'sitegroup_not_found':
          decodedMessage = t("errors.sitegroup_not_found");
          break;
        default:
          decodedMessage = t("errors.general")
          break;
      }
      message({
        title: t("messages.attention").toUpperCase(),
        description: decodedMessage,
        okCallback: () => {},
        okLabel: t("buttons.ok")
      })
    }else{
      navigate('/home', { replace: true });
    }
    setLoading(false);
  };

  const handleNumericChange = (e: any ) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

	return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: 3 }}>
      <TextField
        margin="normal"
        fullWidth
        id="name"
        label={t("name") +'*'}
        error={errors?.name ? true : false}
        helperText={!!errors?.name?.message ?  errors?.name?.message : ''}
        {...register("name")}
        defaultValue={currentUser?.first_name ?? ''}
      />
      <TextField
        margin="normal"
        fullWidth
        id="surname"
        label={t("surname")+'*'}
        error={errors?.surname ? true : false}
        helperText={!!errors?.surname?.message ?  errors?.surname?.message : ''}
        {...register("surname")}
        defaultValue={currentUser?.last_name ?? ''}
      />
      <TextField
        disabled
        margin="normal"
        fullWidth
        id="email"
        label={t("email")+'*'}
        error={errors?.email ? true : false}
        helperText={!!errors?.email?.message ?  errors.email.message : ''}
        {...register("email")}
        defaultValue={currentUser?.email ?? ''}
      />
      <TextField
        margin="normal"
        fullWidth
        id="phone"
        label={t("phone")}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => handleNumericChange(e)}
        value={phone}
      />
      <TextField
        margin="normal"
        fullWidth
        id="address"
        label={t("address")}
        {...register("address")}
        defaultValue={currentUser?.address?.address1 ?? ''}
      />
      <TextField
        margin="normal"
        fullWidth
        id="zip_code"
        label={t("zip_code")}
        inputProps={{ inputMode: 'numeric' }}
        {...register("zip_code")}
        defaultValue={currentUser?.address.postal ?? ''}
      />
      <TextField
        margin="normal"
        fullWidth
        id="city"
        label={t("city")}
        {...register("city")}
        defaultValue={currentUser?.address.city ?? ''}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={isSubmitting ? true : false}
      >
        {t("buttons.save")}
      </Button>
    </Box>
	);
};

export default User;
